import React, { useEffect, useState } from 'react';
import { I18nContext, I18nManager } from '@shopify/react-i18n';
import * as FullStory from '@fullstory/browser';
import { Loading, useAppBridge } from '@shopify/app-bridge-react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { Redirect } from '@shopify/app-bridge/actions';
import { css } from '@emotion/css';

import axios from '../../utils/api/bundle-shopify';
import { useLocale } from '../../hooks/useLocale';
import PolarisAppProvider from './PolarisAppProvider';
import { getNewDashboard } from '../../utils/antiSpy';
import AwtomicLoading from '../AwtomicLoading';
import {
  FEATURE_FLAGS,
  isFeatureFlagEnabled,
} from '../../widgetSrc/utils/featureFlags';
import { getShopPermissions } from '../../lib/permissions';

export default function AppProvider({ Component, pageProps }) {
  const app = useAppBridge();
  const locale = useLocale('en-US');
  const [shopData, setShopData] = useState();
  const [userData, setUserData] = useState();
  const [shopLoaded, setShopLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !Component?.disableAppProvider) {
      window.app = app;

      // Getting shop and auth info
      axios
        .get('/shop')
        .then(({ data = {} }) => {
          if (!data.ShopId) {
            throw new Error('Unable to get shop');
          }

          const installDate = new Date(data.InstallDate);
          const userData = {
            user_id: data.ShopId,
            name: get(data, 'AssociatedUser.first_name', ''),
            email: data.ShopEmail,
            company: {
              company_id: data.ShopId,
              name: data.ShopName,
            },
            private_email: data.ShopPrivateEmail,
            shop_name: data.ShopName,
            created_at: Math.ceil(installDate.getTime() / 1000),
            shop_url: data.ShopUrl,
            app_subscription_status: data.AppSubscriptionStatus,
            dev_store: data.AppSubscriptionTest,
            app_subscription_created_at: data.AppSubscriptionCreatedAt,
            app_public: data.AppPublic,
            account_owner: get(data, 'AssociatedUser.account_owner', ''),
            logged_in_user_collaborator: get(
              data,
              'AssociatedUser.collaborator',
              '',
            ),
            logged_in_user_email: get(data, 'AssociatedUser.email', ''),
            logged_in_user_email_verified: get(
              data,
              'AssociatedUser.email_verified',
              '',
            ),
            logged_in_user_last_name: get(data, 'AssociatedUser.last_name', ''),
            logged_in_user_first_name: get(
              data,
              'AssociatedUser.first_name',
              '',
            ),
            logged_in_user_id: get(data, 'AssociatedUser.id', ''),
            description: data.description,
            eligible_for_subscription_migration:
              data.EligibleForSubscriptionMigration,
            eligible_for_subscriptions: data.EligibleForSubscriptions,
            sells_subscriptions: data.SellsSubscriptions,
            development_shop: data.ShopDevelopment,
            shop_plan: data.ShopifyPlanName,
            shopify_plus: data.ShopifyPlus,
          };

          window.app.SystemSettings = data.SystemSettings;
          window.app.shopDomain = data.ShopDomain;

          const permissionsData = getShopPermissions(data);
          const _shopData = {
            ...data,
            ShopPermissions: permissionsData,
          };

          setShopData(_shopData);
          setUserData(userData);
          setShopLoaded(true);

          const camelCaseUserData = Object.keys(userData).reduce(
            (accum, key) => {
              accum[camelCase(key)] = userData[key];
              return accum;
            },
            {},
          );

          if (!data.isOnlineUserAwtomicCollaborator) {
            window.Intercom('boot', {
              app_id: 'pmmilwjk',
            });

            window.Intercom('update', userData);

            FullStory.identify(data.ShopId, {
              ...userData,
              displayName: data.ShopName,
              email: data.ShopEmail,
            });

            // FIXME eslint error
            // eslint-disable-next-line no-undef
            analytics.identify(data.ShopId, camelCaseUserData);
          }
        })
        .catch((error) => {
          // If we are not already redirecting to /auth we do that on any error
          // as this is the entrypoint of the logic
          if (!error.triggeringOAuth) {
            console.error(error);

            const redirect = Redirect.create(window.app);

            const shop =
              new URL(window.location.href).searchParams.get('shop') || '';

            redirect.dispatch(
              Redirect.Action.REMOTE,
              `https://${window.location.host}/auth?shop=${shop}`,
            );
          }
        });

      FullStory.init({
        orgId: '11JSW3',
        recordOnlyThisIFrame: true,
        devMode: process.env.NODE_ENV === 'development',
      });

      // FullStory Ragehooks to Intercom
      ['fullstory/rageclick'].forEach(function (event) {
        window.addEventListener(event, (evt) => {
          window.Intercom('trackEvent', evt.type, {
            'Session Link': {
              value: 'Play in FullStory',
              url: evt.detail.eventReplayUrlAtCurrentTime,
            },
          });
        });
      });
    }
  }, []);

  if (isFeatureFlagEnabled(FEATURE_FLAGS.LAUNCH_DASHBOARD_v2)) {
    getNewDashboard();
  }

  const i18nManager = new I18nManager({
    locale,
    onError(error) {
      console.error('i18nManager error', error);
    },
  });

  // disableAppProvider can be used to just render a page
  // without loading shop and the  rest of providers
  if (Component?.disableAppProvider) {
    return <Component />;
  }

  return shopLoaded ? (
    <I18nContext.Provider value={i18nManager}>
      <PolarisAppProvider
        Component={Component}
        pageProps={pageProps}
        shopData={shopData}
        userData={userData}
      />
    </I18nContext.Provider>
  ) : (
    <>
      <Loading />
      <div className={styles.loader}>
        <AwtomicLoading />
      </div>
    </>
  );
}

const styles = {
  loader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25% 0;
  `,
};
