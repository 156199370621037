import React, { useContext, useState, Fragment } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Context as ShopifyContext } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { ActionList, Popover, Icon, Badge, Text } from '@shopify/polaris';
import {
  RefreshMajor,
  AddProductMajor,
  MarketingMajor,
  DataVisualizationMajor,
  QuickSaleMajor,
  QuestionMarkInverseMinor,
  SettingsMinor,
  CaretDownMinor,
  MobileHorizontalDotsMajor,
} from '@shopify/polaris-icons';
import { useRouter } from 'next/router';

import en from './translations/en.json';
import { useTranslations } from '../../hooks/useTranslations';
import BundleIcon from '../../components/Icons/BundleIcon';
import { isSessionStorageEnabled } from '../../utils/window-utils';
import { UpdatesContainer } from '../Updates/UpdatesContainer';
import {
  FEATURE_FLAGS,
  isFeatureFlagEnabled,
} from '../../widgetSrc/utils/featureFlags';

// It's confusing if subscriptions tab is highlighted by default and then we
// redirect the user to /launch or any other page. Better to not highlight any
// tab by default leaving this empty.
const DEFAULT_TAB = '';

export default function Navigation() {
  const { i18n } = useTranslations('BundleNavigation', en);

  const redirect = Redirect.create(useContext(ShopifyContext));
  const navigation = {
    leftItems: [
      {
        id: 'analytics',
        content: i18n.translate('Navigation.analytics'),
        accessibilityLabel: i18n.translate('Navigation.analytics'),
        route: 'analytics',
        position: 'left',
      },
      {
        id: 'subscriptions',
        content: i18n.translate('Navigation.subscriptions'),
        accessibilityLabel: i18n.translate('Navigation.subscriptions'),
        route: 'subscriptions',
        position: 'left',
      },
      {
        id: 'tools',
        content: i18n.translate('Navigation.tools'),
        accessibilityLabel: i18n.translate('Navigation.tools'),
        position: 'left',
        subItems: [
          {
            id: 'subscription-plans',
            content: i18n.translate('Navigation.subscriptionPlans'),
            accessibilityLabel: i18n.translate('Navigation.subscriptionPlans'),
            route: 'plans',
            icon: RefreshMajor,
          },
          {
            id: 'addons',
            content: i18n.translate('Navigation.addOns'),
            accessibilityLabel: i18n.translate('Navigation.addOns'),
            route: 'addons',
            icon: AddProductMajor,
          },
          {
            id: 'bundles',
            content: i18n.translate('Navigation.bundles'),
            accessibilityLabel: i18n.translate('Navigation.bundles'),
            route: 'bundles',
            icon: BundleIcon,
          },
          {
            id: 'moments',
            content: i18n.translate('Navigation.moments'),
            accessibilityLabel: i18n.translate('Navigation.moments'),
            route: 'moments',
            icon: MarketingMajor,
          },
          {
            id: 'campaign-links',
            content: i18n.translate('Navigation.campaignLinks'),
            accessibilityLabel: i18n.translate('Navigation.campaignLinks'),
            route: 'campaign-links',
            icon: QuickSaleMajor,
          },
          {
            id: 'projections',
            content: (
              <div className={styles.subnavWithBadge}>
                {i18n.translate('Navigation.projections')}
                <div className={styles.badgeWrapper}>
                  <Badge tone="info" size="small">
                    Beta
                  </Badge>
                </div>
              </div>
            ),
            accessibilityLabel: i18n.translate('Navigation.projections'),
            route: 'inventory',
            icon: DataVisualizationMajor,
          },
        ],
      },
    ],
    rightItems: [
      {
        id: 'help',
        content: i18n.translate('Navigation.help'),
        accessibilityLabel: i18n.translate('Navigation.help'),
        icon: QuestionMarkInverseMinor,
        position: 'right',
        subItems: [
          {
            id: 'launch',
            content: i18n.translate('Navigation.setupGuide'),
            accessibilityLabel: i18n.translate('Navigation.setupGuide'),
            panelID: 'launch',
            route: 'launch',
          },
          {
            id: 'helpCenter',
            content: i18n.translate('Navigation.helpCenter'),
            accessibilityLabel: i18n.translate('Navigation.helpCenter'),
            external: true,
            url: 'https://docs.awtomic.com/docs',
          },
          {
            id: 'contact-us',
            content: i18n.translate('Navigation.contactUs'),
            accessibilityLabel: i18n.translate('Navigation.contactUs'),
            external: true,
            url: 'mailto:support@awtomic.com',
          },
        ],
      },
      {
        id: 'settings',
        content: i18n.translate('Navigation.settings'),
        accessibilityLabel: i18n.translate('Navigation.settings'),
        route: 'settings',
        position: 'right',
        icon: SettingsMinor,
      },
    ],
  };

  const router = useRouter();
  let basePath = (router.pathname || '').split('/')[1] || DEFAULT_TAB;
  if (basePath === 'emails') {
    // Settings tab should be active when user is on emails page
    basePath = 'settings';
  }

  const handleNavSelect = (route) => {
    // get rid of any cached subscription filters or current page
    if (isSessionStorageEnabled()) {
      sessionStorage.removeItem('subscriptionListCurrentPage');
      sessionStorage.removeItem('subscriptionListFilters');
    }
    redirect.dispatch(Redirect.Action.APP, `/${route}`);
  };

  return (
    <div className={styles.navContainer}>
      <div className={styles.navLeftItems}>
        {navigation.leftItems.map(({ id, icon, content, subItems, route }) => (
          <NavItem
            key={id}
            id={id}
            icon={icon}
            content={content}
            subItems={subItems}
            route={route}
            handleTabSelect={handleNavSelect}
            path={basePath}
          />
        ))}
      </div>
      <div className={styles.navRightItems}>
        {navigation.rightItems.map(({ id, icon, content, subItems, route }) => (
          <NavItem
            key={id}
            id={id}
            icon={icon}
            content={content}
            subItems={subItems}
            route={route}
            handleTabSelect={handleNavSelect}
            path={basePath}
          />
        ))}
        {isFeatureFlagEnabled(FEATURE_FLAGS.LAUNCH_PRODUCT_UPDATES) && (
          <UpdatesContainer />
        )}
      </div>
      <OverflowMenu
        rightItems={navigation.rightItems}
        basePath={basePath}
        handleNavSelect={handleNavSelect}
      />
    </div>
  );
}

const NavButton = ({ id, onClick, icon, content, dropdown, active }) => (
  <NavButtonStyled key={id} onClick={onClick} active={active}>
    {icon && <Icon tone="base" source={icon} />}
    <Text as="span" fontWeight={active ? 'medium' : 'regular'}>
      {content}
    </Text>
    {dropdown && <Icon tone="base" source={CaretDownMinor} />}
  </NavButtonStyled>
);

const OverflowMenu = ({ basePath, handleNavSelect, rightItems }) => {
  const activator = (
    <NavButtonStyled onClick={() => setIsOverflowMenuOpen(!isOverflowMenuOpen)}>
      <Icon source={MobileHorizontalDotsMajor} />
    </NavButtonStyled>
  );
  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);
  return (
    <div className={styles.overFlowMenu}>
      <Popover
        active={isOverflowMenuOpen}
        activator={activator}
        onClose={() => setIsOverflowMenuOpen(false)}
        zIndexOverride={999}
      >
        {rightItems.map((item) => {
          const isActive = item.route === basePath;

          if (!item.route && !item.url) {
            return (
              <Fragment key={item.id}>
                <Popover.Pane fixed>
                  <Popover.Section>
                    <span className="Polaris-ActionList__Content">
                      <div className="Polaris-ActionList__Prefix">
                        <Icon source={QuestionMarkInverseMinor} />
                      </div>
                      <Text
                        as="span"
                        variant="bodyLg"
                        fontWeight={isActive ? 'medium' : 'regular'}
                      >
                        {item.content}
                      </Text>
                    </span>
                  </Popover.Section>
                </Popover.Pane>
                <Popover.Pane>
                  <ActionList
                    items={item.subItems.map((subItem) => ({
                      ...subItem,
                      active: subItem.route === basePath,
                      onAction: () => {
                        if (!subItem.external) {
                          setIsOverflowMenuOpen(false);
                          handleNavSelect(subItem.route);
                        }
                      },
                    }))}
                  />
                </Popover.Pane>
              </Fragment>
            );
          } else {
            return (
              <Popover.Pane key={item.id}>
                <ActionList
                  items={[
                    {
                      ...item,
                      active: item.route === basePath,
                      onAction: () => {
                        if (!item.external) {
                          setIsOverflowMenuOpen(false);
                          handleNavSelect(item.route);
                        }
                      },
                    },
                  ]}
                />
              </Popover.Pane>
            );
          }
        })}
      </Popover>
    </div>
  );
};

const NavItem = ({
  id,
  icon,
  content,
  subItems,
  route,
  handleTabSelect,
  accessibilityLabel,
  path,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  if (subItems && subItems.length > 0) {
    const activator = (
      <NavButton
        key={id}
        onClick={() => setIsOpen(!isOpen)}
        icon={icon}
        content={content}
        dropdown
        active={subItems.find((item) => item.route === path)}
        aria-label={accessibilityLabel}
      />
    );
    return (
      <Popover
        active={isOpen}
        activator={activator}
        onClose={() => setIsOpen(false)}
        zIndexOverride={999}
      >
        <Popover.Pane>
          <ActionList
            items={subItems
              .filter(({ shouldRender = true }) => shouldRender)
              .map((subItem) => ({
                ...subItem,
                onAction: () => {
                  if (!subItem.external) {
                    setIsOpen(false);
                    handleTabSelect(subItem.route);
                  }
                },
              }))}
          />
        </Popover.Pane>
      </Popover>
    );
  } else {
    return (
      <NavButton
        key={id}
        onClick={() => handleTabSelect(route)}
        icon={icon}
        active={path === route}
        content={content}
        aria-label={accessibilityLabel}
      />
    );
  }
};

const styles = {
  badgeWrapper: css`
    margin-left: 15px;
  `,
  subnavWithBadge: css`
    display: flex;
    align-items: center;
  `,
  navContainer: css`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: var(--p-space-300) var(--p-space-800);
    list-style: none;
    gap: var(--p-space-100);
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px;
    border-color: var(--p-color-border);
    background-color: var(--p-color-bg-surface-secondary);
  `,
  navLeftItems: css`
    display: flex;
    gap: var(--p-space-100);
  `,
  navRightItems: css`
    @media screen and (max-width: 490px) {
      display: none;
    }
    display: flex;
    gap: var(--p-space-100);
  `,
  overFlowMenu: css`
    @media screen and (min-width: 490px) {
      display: none;
    }
    display: flex;
  `,
};

const NavButtonStyled = styled.button`
  color: ${(props) =>
    props.active ? 'var(--p-text)' : 'var(--p-text-subdued)'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--p-space-100) var(--p-space-300);
  border: none;
  cursor: pointer;
  color: var(--p-color-text-brand);
  &:hover {
    background-color: var(--p-color-bg-fill-transparent-hover);
    border-radius: var(--p-border-radius-200);
  }
  letter-spacing: var(--p-text-body-sm-font-letter-spacing);
  line-height: var(--p-text-body-sm-font-line-height);
  border-radius: var(--p-border-radius-200);
  background-color: transparent;
  ${(props) =>
    props.active
      ? ' background: var(--p-color-bg-fill-transparent-selected);'
      : ''}

  box-shadow: ${(props) =>
    props.active ? 'inset 0 -3px 0 0 var(--p-action-primary)' : 'none'};
`;
