import { TranslationDictionary, useI18n } from '@shopify/react-i18n';

const getTranslationsDictionary = (locale: string) => {
  return import(
    /* webpackChunkName: "Bundle-i18n", webpackMode: "lazy-once" */ `./translations/${locale}.json`
  )
    .then((bundleTranslations) => bundleTranslations.default)
    .catch((_e) => {
      // do nothing
    });
};

export const useTranslations = (
  id: string,
  fallback: TranslationDictionary,
) => {
  const [i18n] = useI18n({
    id,
    fallback,
    translations(locale) {
      return getTranslationsDictionary(locale);
    },
  });

  return {
    i18n,
  };
};
