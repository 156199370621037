import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      .Polaris-DataTable__Cell--header {
        border-bottom: 0.1rem solid var(--p-divider);
      }
      .Polaris-Filters__FiltersContainerHeader {
        margin-top: 3.2rem;
      }
      .Polaris-Filters__FiltersMobileContainerContent {
        height: calc(100% - 10.8rem);
      }
      .Polaris-Filters__FiltersDesktopContainerContent {
        height: calc(100% - 10.8rem);
      }
      .Polaris-Filters__FiltersContainerFooter {
        background-color: white;
      }
      .Polaris-Banner--withinPage {
        margin-bottom: 16px;
      }
      .Polaris-Modal-Dialog__Modal {
        max-height: calc(100vh - 8rem);
      }
    `}
  />
);

export default GlobalStyles;
