/**
 * This axios instance is used to make requests from the admin app FE to the awtomic-api
 * It gets a temporary JWT from the bundle-shopify BE and adds it to the request headers
 */

import axios from 'axios';
import { DateTime } from 'luxon';
import jwt from 'jsonwebtoken';

import axiosBundleShopify from './bundle-shopify';

const API_BASE_URL_PROD = 'https://shapi.bundlepayments.com';
const API_BASE_URL_QA = 'https://shapi.bundleqa.com';
const API_BASE_URL_DEV = 'http://localhost:8082';

export const API_BASE_URL =
  {
    qa: API_BASE_URL_QA,
    production: API_BASE_URL_PROD,
    dev: API_BASE_URL_DEV,
  }[process.env.BUNDLE_ENV || 'production'] || API_BASE_URL_PROD;

// Global variable where we store the JWT
let authToken = null;

const checkJWTExpiration = (token) => {
  const payload = jwt.decode(token);
  const now = DateTime.utc().toSeconds();

  return payload && payload.exp && payload.exp > now;
};

const getAuthToken = async () => {
  if (authToken && checkJWTExpiration(authToken)) {
    return authToken;
  }

  const { data } = await axiosBundleShopify.post('/api/awtomic-api-auth-token');

  authToken = data.authToken;

  return authToken;
};

const instance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor: add JWT to request headers
instance.interceptors.request.use(async (config) => {
  config.headers['Authorization'] = await getAuthToken();
  return config;
});

// Response interceptor: if JWT is expired, get a new one and retry the request up to 10 times
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response.status;
    error.config.retries = error.config?.retries || 0;

    if (status === 401 && error.config.retries < 10) {
      error.config.headers['Authorization'] = await getAuthToken();

      error.config.retries++;
      return instance(error.config);
    }

    return Promise.reject(error);
  },
);

export const CancelToken = axios.CancelToken;

export default instance;
