import { get } from 'lodash';

// grabs specified param from window.location.search if it exists or returns undefined
export function getParamFromSearch(paramKey) {
  if (typeof window === 'undefined' || !paramKey) return;

  const searchParams = get(window, 'location.search', '')
    .replace('?', '')
    .split('&');

  const searchHash = searchParams.reduce((accum, param) => {
    const [key, value] = param.split('=');
    accum[key] = value;
    return accum;
  }, {});

  return searchHash[paramKey];
}

export const isSessionStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.sessionStorage.setItem(key, null);
    window.sessionStorage.removeItem(key);
    window.sessionStorage.getItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

const getUserLocale = () => {
  return get(window, 'navigator.language', 'en-US');
};

export const getCurrencySymbol = (currency, locale = getUserLocale()) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const getCurrencyName = (currency, locale = getUserLocale()) => {
  if (!Intl?.DisplayNames || typeof Intl.DisplayNames !== 'function')
    return 'Dollar'; // Not fully supported API. Fallback to Dollars
  const currencyName = new Intl.DisplayNames([locale], { type: 'currency' });

  return currencyName.of(currency);
};
