/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';

interface ShopContextProps {
  shop: any;
  setShop: (newShop: any) => void;
}

const ShopContext = createContext<ShopContextProps>({
  shop: undefined,
  setShop: (newShop: any) => {},
});
ShopContext.displayName = 'ShopContext';

interface ShopProviderProps {
  children: React.ReactNode;
  shop: any;
  setShop?: (newShop: any) => void;
}

export const ShopProvider: React.FC<ShopProviderProps> = ({
  children,
  shop,
}) => {
  const [shopData, setShopData] = useState(shop);

  return (
    <ShopContext.Provider
      value={{
        shop: shopData,
        setShop: setShopData,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export const useShopContext = (): ShopContextProps => {
  const context = useContext(ShopContext);

  if (context === undefined) {
    throw new Error('useShopContext must be used within a ShopContextProvider');
  }

  return context;
};
