import React from 'react';
import { css } from '@emotion/css';

export default function AwtomicLoading() {
  return (
    <svg
      className={styles.svg}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.path}
        strokeDasharray="500 500"
        d="M16.4033 41.8193L39.446 41.8193L26.4839 8.69402L18.5626 8.69402L2 41.8193L10.6414 41.8193L22.1632 19.4957L27.204 34.6182H20.0028L16.4033 41.8193Z"
      ></path>
    </svg>
  );
}

const styles = {
  svg: css`
    width: 100px;
    height: 100px;
  `,
  path: css`
    fill: #222;
    stroke: #222;
    stroke-width: 0;
    animation: logo 2.3s linear infinite;

    @keyframes logo {
      0% {
        stroke-width: 1pt;
        stroke-dashoffset: 500;
        fill-opacity: 0;
      }
      10% {
        fill-opacity: 0;
      }
      60% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
        stroke-width: 0pt;
      }
      100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
        stroke-width: 0pt;
      }
    }
  `,
};
