import React, { useEffect, useState } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { AppProvider } from '@shopify/polaris';
import { useAppBridge } from '@shopify/app-bridge-react';
import polarisTranslations from '@shopify/polaris/locales/en.json';
import { useI18n } from '@shopify/react-i18n';
import { authenticatedFetch } from '@shopify/app-bridge/utilities';

import Modal, { useModal, ModalContext } from '../Modal';
import { ShopProvider } from '../Shop';
import { UserProvider } from '../User';
import Navigation from './Navigation';

const getPolarisTranslationsDictionary = (locale) => {
  try {
    return import(
      /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
    )
      .then((polarisTranslations) => polarisTranslations.default)
      .catch(() => {
        console.error(
          `failed to get polaris translation files for locale: ${locale}`,
        );
      });
  } catch (e) {
    return import(
      /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/en.json`
    );
  }
};

export default function PolarisAppProvider({
  Component,
  pageProps,
  shopData,
  userData,
}) {
  const [apolloClient, setApolloClient] = useState();
  const app = useAppBridge();

  useEffect(() => {
    const loadApolloClient = async () => {
      const client = new ApolloClient({
        link: createHttpLink({
          credentials: 'include',
          fetch: authenticatedFetch(app, (uri, options) => {
            // When sending this header, the server returns a 302 redirect
            // and breakes the flow. Removing this header that we don't use
            // fix the problem.
            if (options.headers['X-Requested-With']) {
              delete options.headers['X-Requested-With'];
            }
            if (options.headers['x-requested-with']) {
              delete options.headers['x-requested-with'];
            }

            return fetch(uri, options);
          }),
        }),
        cache: new InMemoryCache(),
      });

      setApolloClient(client);
    };

    loadApolloClient();
  }, []);

  const [i18n] = useI18n({
    id: 'Polaris',
    fallback: polarisTranslations,
    translations(locale) {
      return getPolarisTranslationsDictionary(locale);
    },
  });
  const {
    isOpen,
    openModal,
    closeModal,
    modalContent,
    setModalContent,
    modalProps,
  } = useModal();

  return (
    <AppProvider i18n={i18n.translations}>
      {apolloClient && (
        <ApolloProvider client={apolloClient}>
          <ShopProvider shop={shopData}>
            <Navigation />

            <div>
              <Modal isOpen={isOpen} {...modalProps} />
              <UserProvider user={userData}>
                <ModalContext.Provider
                  value={{
                    isOpen,
                    closeModal,
                    openModal,
                    modalContent,
                    setModalContent,
                    props: modalProps,
                  }}
                >
                  <Component {...pageProps} shop={shopData.ShopDomain} />
                </ModalContext.Provider>
              </UserProvider>
            </div>
          </ShopProvider>
        </ApolloProvider>
      )}
    </AppProvider>
  );
}
