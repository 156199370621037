import React, { useState } from 'react';
import { css } from '@emotion/css';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Divider,
  Icon,
  InlineStack,
  Popover,
  Text,
} from '@shopify/polaris';
import { NotificationMajor } from '@shopify/polaris-icons';
import { DateStyle } from '@shopify/react-i18n';
import en from './translations/en.json';
import { useTranslations } from '../../hooks/useTranslations';
import { useProductUpdates } from '../../hooks/useProductUpdates';
import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';

function UpdatesList({
  i18n,
  productUpdates,
  lastProductUpdateViewedIndex,
  redirect,
  closePopover,
}) {
  return (
    <div className={styles.container}>
      <Box padding="300">
        <Box padding="300" paddingBlockStart="100">
          <Text as="h2" variant="headingMd" alignment="center">
            {i18n.translate('ProductUpdates.notifications')}
          </Text>
        </Box>
        <Bleed marginInlineStart="400" marginInlineEnd="400">
          <Divider />
        </Bleed>
        {productUpdates.map((update, index) => (
          <div key={index}>
            <Box
              paddingBlockStart="600"
              paddingBlockEnd="600"
              padding="300"
              minWidth="100%"
            >
              <BlockStack gap="200">
                <InlineStack align="start" blockAlign="center">
                  <Text as="p" tone="subdued" variant="bodyXs">
                    {(update.type && update.type) ||
                      i18n.translate('ProductUpdates.update')}{' '}
                    •{' '}
                    {i18n.formatDate(new Date(update.createdAt), {
                      style: DateStyle.Short,
                    })}
                  </Text>
                </InlineStack>
                {update.title && (
                  <Text as="h5" fontWeight="semibold">
                    {update.title}
                  </Text>
                )}
                {update.image && (
                  <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    src={update.image}
                  />
                )}
                {update.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: update.description,
                    }}
                  />
                )}
                {(update.url || update.appPath) && (
                  <Box paddingBlockStart="200">
                    {update.url && (
                      <Button size="micro" url={update.url} external>
                        {update.buttonLabel ||
                          i18n.translate('ProductUpdates.buttonLabel')}
                      </Button>
                    )}
                    {update.appPath && (
                      <Button
                        size="micro"
                        onClick={() => {
                          redirect.dispatch(
                            Redirect.Action.APP,
                            update.appPath,
                          );
                          closePopover();
                        }}
                      >
                        {update.buttonLabel ||
                          i18n.translate('ProductUpdates.buttonLabel')}
                      </Button>
                    )}
                  </Box>
                )}
              </BlockStack>
            </Box>
            {/* Add a divider after each update except the one before the last viewed index */}
            {index === lastProductUpdateViewedIndex ? (
              <div className={styles.lastViewedDivider}>
                <Text as="p" variant="bodySm" fontWeight="semibold">
                  {i18n.translate('ProductUpdates.caughtUp')}
                </Text>
              </div>
            ) : (
              <Bleed marginInline="300">
                <Divider />
              </Bleed>
            )}
          </div>
        ))}
        <Box padding="300" paddingBlockEnd="100">
          <Text as="p" tone="subdued" alignment="center" fontWeight="bold">
            {i18n.translate('ProductUpdates.endOfUpdates')}
          </Text>
        </Box>
      </Box>
    </div>
  );
}

export const UpdatesContainer = () => {
  const { i18n } = useTranslations('UpdatesList', en);
  const [isOpen, setIsOpen] = useState(false);

  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const {
    productUpdates,
    lastProductUpdateViewedIndex,
    hasUnviewedProductUpdates,
    setProductUpdatesViewed,
  } = useProductUpdates();

  const activator = (
    <div
      onClick={() => {
        setIsOpen(!isOpen);
        setProductUpdatesViewed();
      }}
      className={styles.updateContainerButton}
    >
      <Icon source={NotificationMajor} />
      {hasUnviewedProductUpdates && <span className={styles.updateIconBadge} />}
    </div>
  );
  return (
    <Popover
      active={isOpen}
      activator={activator}
      onClose={() => setIsOpen(false)}
    >
      <Popover.Pane>
        <UpdatesList
          i18n={i18n}
          productUpdates={productUpdates}
          lastProductUpdateViewedIndex={lastProductUpdateViewedIndex}
          redirect={redirect}
          closePopover={() => setIsOpen(false)}
        />
      </Popover.Pane>
    </Popover>
  );
};

const styles = {
  container: css`
    overflow-x: hidden;
  `,
  updateContainerButton: css`
    color: var(--p-text-subdued);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 6px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    &:hover {
      color: var(--p-text);
      .Polaris-Icon {
        background-color: var(--p-color-bg-surface-tertiary-hover);
      }
    }
    .Polaris-Icon {
      border-radius: var(--p-border-radius-150);
      background-color: var(--p-color-bg-surface-tertiary);
      transition: background-color 0.2s ease;
      padding: 10px;
      height: 40px;
      width: 40px;
    }
    position: relative;
  `,
  updateIconBadge: css`
    position: absolute;
    top: 3px;
    right: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--p-color-bg-fill-critical);
    animation: zoomIn 0.5s;
    animation-timing-function: ease-in;
    &:after {
      position: absolute;
      bottom: 0;
      top: 0px;
      right: 0px;
      width: 14px;
      height: 14px;
      z-index: 9;
      content: '';
      border: 2px solid var(--p-color-bg-fill-critical);
      border-radius: 50%;
      animation: sonar 5.5s 0.55s;
      animation-iteration-count: 5;
      animation-timing-function: ease-in-out;
    }
    @keyframes zoomIn {
      0% {
        transform: scale(0.1);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    @keyframes sonar {
      0% {
        transform: scale(0.9);
        opacity: 1;
      }
      25% {
        transform: scale(2);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  `,
  lastViewedDivider: css`
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    width: 120px;
    margin: 0 auto;
    &:after {
      content: '';
      border-top: 1px solid var(--p-color-border);
      position: absolute;
      top: 48%;
      left: -200px;
      right: -200px;
      z-index: -1;
    }
  `,
};
