import React, { createContext, useContext } from 'react';

interface UserContextProps {
  user: any;
}

const UserContext = createContext<UserContextProps>({
  user: undefined,
});
UserContext.displayName = 'UserContext';

interface UserProviderProps {
  children: React.ReactNode;
  user: any;
}

export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  user,
}) => {
  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return context;
};
