/**
 * This axios instance is used to make requests from the admin app FE to the admin app BE
 * It gets the session token from the app bridge and adds it to the request headers
 * and redirects to the auth page if the token is invalid
 */

import axios from 'axios';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';

const instance = axios.create();

// Request interceptor: add Shopify auth token to requests
instance.interceptors.request.use(function (config) {
  return getSessionToken(window.app).then((token) => {
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  });
});

// Response interceptor: redirect to auth when Shopify session token is invalid
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const headers = error.response?.headers || [];

    if (headers['x-shopify-api-request-failure-reauthorize'] === '1') {
      const authUrlHeader =
        headers['x-shopify-api-request-failure-reauthorize-url'] || `/auth`;

      const redirectUrl = new URL(
        authUrlHeader.startsWith('/')
          ? `https://${window.location.host}${authUrlHeader}`
          : authUrlHeader,
      );

      const currentPath = window?.location?.pathname;

      if (currentPath) {
        redirectUrl.searchParams.set('appRedirectPath', currentPath);
      }

      error.triggeringOAuth = true;

      // checking if we are requesting new scopes in order to show a nicer message to merchants
      // if (headers['x-app-requesting-new-scopes'] === '1') {
      //   const redirect = Redirect.create(window.app);
      //   redirect.dispatch(
      //     Redirect.Action.APP,
      //     `/apprequestingnewscopes?redirectUri=${redirectUrl.toString()}`,
      //   );
      // } else {
      const redirect = Redirect.create(window.app);
      redirect.dispatch(Redirect.Action.REMOTE, redirectUrl.toString());
      // }
    }

    return Promise.reject(error);
  },
);

export const CancelToken = axios.CancelToken;

export default instance;
