import { useState, useEffect } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';

export function useLocale(initialLocale = 'en-US') {
  const [locale, setLocale] = useState(initialLocale);
  const app = useAppBridge();

  useEffect(() => {
    app.getState().then((data) => {
      const { staffMember } = data;
      setLocale(staffMember?.locale);
    });
  });

  return locale;
}
