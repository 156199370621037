import queryString from 'query-string';

export enum FEATURE_FLAGS {
  TEST_FLAG_DO_NOT_REMOVE = 'featureFlagUsedForTestingPurposesDoNotRemove',
  LAUNCH_DASHBOARD_v2 = 'launchDashboardV2',
  LAUNCH_HISTORY_PRICE_ON_BAB = 'launchHistoryPriceOnBaB',
  LAUNCH_AWT_MODS = 'launchAwtMods',
  SENTRY_TEST = 'launchSentryTest',
  SENTRY_TEST_2 = 'launchSentryTest2',
  LAUNCH_BAB_VARIANT_SELECTOR = 'launchBaBVariantSelector',
  LAUNCH_MOMENTS_SEGMENTATION = 'launchMomentsSegmentation',
  LAUNCH_DUPLICATE_SUBSCRIPTION = 'launchDuplicateSubscription',
  LAUNCH_BAB_IMPROVEMENTS = 'launchBaBImprovements',
  LAUNCH_PRODUCT_UPDATES = 'launchProductUpdates',
  LAUNCH_UPGRADE_BAB_FROM_LEGACY = 'launchUpgradeBaBFromLegacy',
  LAUNCH_ANALYTICS_PHASE2 = 'launchAnalyticsPhase2',
  LAUNCH_BULK_UPDATES_ADD_PRODUCT = 'launchBulkUpdatesAddProduct',
  LAUNCH_BULK_UPDATES_BATCH_OPTIONS = 'launchBulkUpdatesBatchOptions',
  LAUNCH_INVENTORY_IMPROVEMENTS = 'launchInventoryImprovements',
  LAUNCH_AUTOSWAP_WITH_SECTIONS = 'launchAutoswapWithSections',
}

// returns true if the feature flag is enabled for the shop or via url param
export const isFeatureFlagEnabled = (flag: FEATURE_FLAGS): boolean => {
  let enabled = false;
  if (typeof window !== 'undefined') {
    const search = queryString.parse(window?.location.search);

    const enabledByUrlParam = Object.keys(search).includes(flag);
    const enabledBySettings =
      !!(window as any)?.app?.SystemSettings?.[flag] ||
      !!(window as any)?.reactInit?.systemSettings?.[flag];

    enabled = enabledByUrlParam || enabledBySettings;
  }
  return enabled;
};

export const isQAorDevEnvironment = (): boolean => {
  return (
    process.env.BUNDLE_ENV === 'qa' ||
    process.env.BUNDLE_ENV === 'dev' ||
    process.env.NODE_ENV === 'development'
  );
};

export const isQAorDevEnvironmentOrFlagged = (flag: FEATURE_FLAGS): boolean => {
  return isQAorDevEnvironment() || isFeatureFlagEnabled(flag);
};
