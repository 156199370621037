import { TIERS_PERMISSIONS, PLANS_TIERS_MAPPING } from './constants';

export const getShopPermissions = (shop: any) => {
  const shopPlanName = shop?.AppSubscriptionName;
  const customGrants = shop?.AppTierCustomGrants || [];
  const customRevokes = shop?.AppTierCustomRevokes || [];

  // If no tier is set, try to get it from the subscription name
  // If subscription name is not found either (probably due to custom plan)
  // use default tier instead (professional)
  const shopTier =
    shop.AppSubscriptionTier && shop.AppSubscriptionTier in TIERS_PERMISSIONS
      ? shop.AppSubscriptionTier
      : PLANS_TIERS_MAPPING[shopPlanName] || PLANS_TIERS_MAPPING._default;

  const permissions = [...TIERS_PERMISSIONS[shopTier], ...customGrants];

  return permissions.filter(
    (permission) => !customRevokes.includes(permission),
  );
};
