/*
 * Permissions
 * Each permission sets access to a specific feature.
 */
export enum Permissions {
  apiKeys = 'apiKeys',
  babAutoswap = 'babAutoswap',
  bundlesBab = 'bundlesBab',
  bundlesShuffles = 'bundlesShuffles',
  cpBabOpenEditByDefault = 'cpBabOpenEditByDefault',
  cpBannerMessage = 'cpBannerMessage',
  giftSubscriptions = 'giftSubscriptions',
  moments = 'moments',
  smsNotifications = 'smsNotifications',
  subscriptions = 'subscriptions',
}

/*
 * Plans
 * Plans determine a shop's pricing
 * These plans come from Shopify
 */

export enum Plans {
  // Current plans
  developer = 'Awtomatic Subscriptions - Developer',
  boxStarter = 'Box Starter',
  subscriptionStarter = 'Subscription Starter',
  subscriptionProfessional = 'Awtomic Subscriptions - Standard',

  // Legacy plans
  legacyStandard = 'Bundle Subscriptions - Standard',
  legacyProfessional = 'Bundle Subscriptions - Pro',
  legacyStarter = 'Awtomatic Subscriptions - Starter',
  legacyAwtomicStarter = 'Awtomic Subscriptions - Starter',
  legacyAwtomaticSubscriptions = 'Awtomatic Subscriptions',
  legacyAwtomaticEnterprise = 'Awtomatic Subscriptions - Enterprise',
  legacyAwtomicSubscriptions = 'Awtomic Subscriptions',
  legacyAwtomicEnterprise = 'Awtomic Subscriptions - Enterprise',
}

/*
 * Tiers
 * Each tier consists of a set of permissions
 * Plans are always bound to tiers, never to individual permissions
 */
export enum Tiers {
  developerV0 = 'developerV0',
  basicV0 = 'basicV0',
  starterV0 = 'starterV0',
  fullV0 = 'fullV0',
}

/*
 * List of permissions for each tier
 *
 * PERMISSIONS CAN BE ADDED HERE TO EACH TIER, BUT NEVER REMOVED
 *
 * This is because current shops need to maintain their current permissions
 * If new shops within an existing tier are not supposed to have a specific permission anymore
 * a new tier must be created and update PLANS_TIERS_MAPPING to point the plan to that new tier
 *
 * This way, the old shop keep their permissions, while the new ones get the new permissions
 */
export const TIERS_PERMISSIONS = {
  [Tiers.basicV0]: [Permissions.bundlesBab],
  [Tiers.starterV0]: [Permissions.bundlesBab, Permissions.subscriptions],
  [Tiers.developerV0]: [
    Permissions.apiKeys,
    Permissions.babAutoswap,
    Permissions.bundlesBab,
    Permissions.bundlesShuffles,
    Permissions.cpBabOpenEditByDefault,
    Permissions.cpBannerMessage,
    Permissions.giftSubscriptions,
    Permissions.moments,
    Permissions.subscriptions,
  ],
  [Tiers.fullV0]: [
    Permissions.apiKeys,
    Permissions.babAutoswap,
    Permissions.bundlesBab,
    Permissions.bundlesShuffles,
    Permissions.cpBabOpenEditByDefault,
    Permissions.cpBannerMessage,
    Permissions.giftSubscriptions,
    Permissions.moments,
    Permissions.smsNotifications,
    Permissions.subscriptions,
  ],
};

/*
 * Correlation between plans and tiers
 * This section can be updated
 *
 * If a plan loses permissions, a new tier must be created and the plan must be mapped to that tier
 */
export const PLANS_TIERS_MAPPING = {
  [Plans.developer]: Tiers.developerV0,
  [Plans.boxStarter]: Tiers.basicV0,
  [Plans.subscriptionStarter]: Tiers.starterV0,
  [Plans.subscriptionProfessional]: Tiers.fullV0,
  [Plans.legacyAwtomaticEnterprise]: Tiers.fullV0,
  [Plans.legacyAwtomicEnterprise]: Tiers.fullV0,
  [Plans.legacyStandard]: Tiers.starterV0,
  [Plans.legacyProfessional]: Tiers.starterV0,
  [Plans.legacyStarter]: Tiers.starterV0,
  [Plans.legacyAwtomicStarter]: Tiers.starterV0,
  [Plans.legacyAwtomaticSubscriptions]: Tiers.starterV0,
  [Plans.legacyAwtomicSubscriptions]: Tiers.starterV0,
  _default: Tiers.fullV0,
};
