import React, { createContext, Dispatch } from 'react';
import { ComplexAction, Modal } from '@shopify/polaris';

const defaultModalProps = {
  isOpen: false,
  onClose: () => undefined,
  title: '',
  primaryAction: {},
  secondaryActions: [],
  content: '',
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  primaryAction: ComplexAction;
  secondaryActions?: ComplexAction[];
  content: React.ReactNode;
}

export default function CustomModal({
  isOpen,
  onClose,
  title,
  primaryAction,
  secondaryActions,
  content,
}: ModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      {content}
    </Modal>
  );
}

export const useModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [modalContent, setModalContent] = React.useState(null);
  const [modalProps, setModalProps] = React.useState<ModalProps>(
    defaultModalProps,
  );
  const openModal = (modalProps: ModalProps) => {
    setIsOpen(true);
    setModalProps(modalProps);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalProps(defaultModalProps);
  };
  return {
    isOpen,
    modalContent,
    setModalContent,
    openModal,
    closeModal,
    setModalProps,
    modalProps,
  };
};

export const ModalContext = createContext({
  setModalContent: (() => undefined) as Dispatch<any>,
  isOpen: false,
  openModal: (modalProps: ModalProps) => undefined,
  closeModal: () => undefined,
  modalContent: null,
  props: {},
});
