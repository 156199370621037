const PRODUCTION = 'production';
const DEV = 'dev';
const QA = 'qa';

export const envConfig = {
  [PRODUCTION]: {
    env: PRODUCTION,
    tracesSampleRate: 0.1,
    sampleRate: 1,
    replaysOnErrorSampleRate: 0.05,
  },
  [DEV]: {
    env: DEV,
    tracesSampleRate: 1,
    sampleRate: 1,
    replaysOnErrorSampleRate: 0.05,
  },
  [QA]: {
    env: QA,
    tracesSampleRate: 1,
    sampleRate: 1,
    replaysOnErrorSampleRate: 0.05,
  },
};

export const HAS_WINDOW = typeof window !== 'undefined';
