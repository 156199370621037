import React from 'react';
import Head from 'next/head';
import { Provider, ClientRouter } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withRouter } from 'next/router';

import AppProvider from '../components/AppProvider/AppProvider';
import GlobalStyles from '../components/GlobalStyles/Styles';
import '@shopify/polaris/build/esm/styles.css';
import type { AppProps } from 'next/app';
import './_app.css';
import { AppConfigV2 } from '@shopify/app-bridge';

const queryClient = new QueryClient();

function App({ Component, pageProps, router }: AppProps) {
  // We are reloading this root component and losing the appBridgeConfig.host which
  // can't be recovered in subsequent page views in the app and the app fails
  // We should change provider's chain to avoid reloading this
  // We also should change the provider's chain order of components according
  // to the Shopify docs and sample app
  const host = (router.query.host ||
    (typeof window != 'undefined' && window.__SHOPIFY_HOST)) as string;

  if (typeof window != 'undefined') {
    window.__SHOPIFY_HOST = host;
  }

  const appBridgeConfig: AppConfigV2 = {
    host,
    apiKey: process.env.API_KEY,
    forceRedirect: true,
  };

  const segmentKey =
    process.env.NODE_ENV === 'development'
      ? 'xebJj2JAVenx6dKEpprDVXeVk9leoSCX'
      : '5LriwF4C9Uk8Lslv8c1HuEKpbTr7TVD7';
  const segmentScript = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentKey}";analytics.SNIPPET_VERSION="4.13.2";analytics.load("${segmentKey}");analytics.page();}}();`;

  const intercomScript = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pmmilwjk';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  // Fn to get locally the session token in order to use it in Insomnia / curl
  if (typeof window !== 'undefined' && process.env.BUNDLE_ENV === 'dev') {
    window.getSessionToken = () =>
      getSessionToken(window.app).then(console.log);
  }

  return (
    <React.Fragment>
      <Head>
        <title>Awtomic</title>
        <meta charSet="utf-8" />
        {/* Intercom */}
        <script
          dangerouslySetInnerHTML={{
            __html: intercomScript,
          }}
        />
        {/* Segment */}
        <script
          dangerouslySetInnerHTML={{
            __html: segmentScript,
          }}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        {appBridgeConfig.host && (
          <Provider config={appBridgeConfig} router={router as any}>
            <GlobalStyles />
            <ClientRouter history={router} />
            <AppProvider Component={Component} pageProps={pageProps} />
          </Provider>
        )}
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default withRouter(App);
