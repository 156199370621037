import { useState, useEffect } from 'react';
import awtomicApi from '../utils/api/awtomic-api';
import { useShopContext } from '../components/Shop';

interface ProductUpdate {
  createdAt: string;
  type?: string;
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  appPath?: string;
  buttonLabel?: string;
}

interface UseProductUpdatesResult {
  productUpdates: ProductUpdate[];
  loading: boolean;
  setProductUpdatesViewed: () => Promise<void>;
  hasUnviewedProductUpdates: boolean;
  lastProductUpdateViewedIndex: number; // this is the index of the last product update the user viewed
}

export const useProductUpdates = (): UseProductUpdatesResult => {
  const [productUpdates, setProductUpdates] = useState<ProductUpdate[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasUnviewedProductUpdates, setHasUnviewedProductUpdates] =
    useState(false);
  const [lastProductUpdateViewedIndex, setLastProductUpdateViewedIndex] =
    useState<number>(-1);

  const { shop } = useShopContext();
  const { LastProductUpdateViewDate: lastProductUpdateViewDate } = shop || {};

  useEffect(() => {
    getProductUpdates();
  }, []);

  useEffect(() => {
    // find the first product update that the user has not viewed
    const lastViewedIndex = productUpdates.findIndex(
      (update) =>
        new Date(update.createdAt).getTime() >
        new Date(lastProductUpdateViewDate).getTime(),
    );

    setLastProductUpdateViewedIndex(lastViewedIndex);
    setHasUnviewedProductUpdates(lastViewedIndex !== -1);
  }, [productUpdates, lastProductUpdateViewDate]);

  const getProductUpdates = async () => {
    try {
      setLoading(true);
      const {
        data: { productUpdates },
      } = await awtomicApi(`/shops/product-updates`);
      setProductUpdates(productUpdates);
    } catch (e: any) {
      // swallow error - its not that big of a deal for product udpates to fail
    } finally {
      setLoading(false);
    }
  };

  const setProductUpdatesViewed = async () => {
    setHasUnviewedProductUpdates(false);

    try {
      await awtomicApi.post('/shops/product-updates-view-date');
    } catch (e: any) {
      // swallow error - its not that big of a deal for product udpates to fail
    }
  };

  return {
    productUpdates,
    loading,
    hasUnviewedProductUpdates,
    setProductUpdatesViewed,
    lastProductUpdateViewedIndex,
  };
};
