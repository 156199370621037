var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"26e065d223c2fe1337bb9a7609f41cd3433bfa19"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { envConfig } from './utils/constants';

if (!process?.env?.CI && process?.env?.BUNDLE_ENV !== 'dev') {
  Sentry.init({
    dsn:
      'https://bfeaaff9b9454428bcbdc116140cdfc6@o546951.ingest.sentry.io/5812389',
    environment: envConfig[process.env.BUNDLE_ENV].env,
    tracesSampleRate: envConfig[process.env.BUNDLE_ENV].tracesSampleRate,
    sampleRate: envConfig[process.env.BUNDLE_ENV].sampleRate || 1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        blockAllMedia: false,
      }),
    ],
  });
}
