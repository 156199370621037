export const getNewDashboard = () => {
  [][
    (![] + [])[+[]] +
      (![] + [])[!+[] + !+[]] +
      (![] + [])[+!+[]] +
      (!![] + [])[+[]]
  ][
    ([][
      (![] + [])[+[]] +
        (![] + [])[!+[] + !+[]] +
        (![] + [])[+!+[]] +
        (!![] + [])[+[]]
    ] + [])[!+[] + !+[] + !+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      ([][[]] + [])[+!+[]] +
      (![] + [])[!+[] + !+[] + !+[]] +
      (!![] + [])[+[]] +
      (!![] + [])[+!+[]] +
      ([][[]] + [])[+[]] +
      ([][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ] + [])[!+[] + !+[] + !+[]] +
      (!![] + [])[+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      (!![] + [])[+!+[]]
  ](
    (!![] + [])[+[]] +
      (+(+!+[] + [+[]] + [+!+[]]))[
        (!![] + [])[+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          ([] + [])[
            ([][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ] + [])[!+[] + !+[] + !+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              ([][[]] + [])[+!+[]] +
              (![] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] + [])[+!+[]] +
              ([][[]] + [])[+[]] +
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              (!![] + [])[+!+[]]
          ][
            ([][[]] + [])[+!+[]] +
              (![] + [])[+!+[]] +
              ((+[])[
                ([][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ] + [])[!+[] + !+[] + !+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  ([][[]] + [])[+!+[]] +
                  (![] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] + [])[+!+[]] +
                  ([][[]] + [])[+[]] +
                  ([][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  (!![] + [])[+!+[]]
              ] + [])[+!+[] + [+!+[]]] +
              (!![] + [])[!+[] + !+[] + !+[]]
          ]
      ](!+[] + !+[] + [+!+[]])[+!+[]] +
      (!![] + [])[+!+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      (+(!+[] + !+[] + !+[] + [!+[] + !+[]]))[
        (!![] + [])[+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          ([] + [])[
            ([][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ] + [])[!+[] + !+[] + !+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              ([][[]] + [])[+!+[]] +
              (![] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] + [])[+!+[]] +
              ([][[]] + [])[+[]] +
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              (!![] + [])[+!+[]]
          ][
            ([][[]] + [])[+!+[]] +
              (![] + [])[+!+[]] +
              ((+[])[
                ([][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ] + [])[!+[] + !+[] + !+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  ([][[]] + [])[+!+[]] +
                  (![] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] + [])[+!+[]] +
                  ([][[]] + [])[+[]] +
                  ([][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  (!![] + [])[+!+[]]
              ] + [])[+!+[] + [+!+[]]] +
              (!![] + [])[!+[] + !+[] + !+[]]
          ]
      ](!+[] + !+[] + !+[] + [!+[] + !+[] + !+[]]) +
      (+[![]] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+!+[]]] +
      ([][[]] + [])[+!+[]] +
      (!![] + [])[!+[] + !+[] + !+[]] +
      (+(!+[] + !+[] + !+[] + [!+[] + !+[]]))[
        (!![] + [])[+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          ([] + [])[
            ([][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ] + [])[!+[] + !+[] + !+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              ([][[]] + [])[+!+[]] +
              (![] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] + [])[+!+[]] +
              ([][[]] + [])[+[]] +
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
              (!![] + [])[+[]] +
              (!![] +
                [][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ])[+!+[] + [+[]]] +
              (!![] + [])[+!+[]]
          ][
            ([][[]] + [])[+!+[]] +
              (![] + [])[+!+[]] +
              ((+[])[
                ([][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ] + [])[!+[] + !+[] + !+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  ([][[]] + [])[+!+[]] +
                  (![] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] + [])[+!+[]] +
                  ([][[]] + [])[+[]] +
                  ([][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ] + [])[!+[] + !+[] + !+[]] +
                  (!![] + [])[+[]] +
                  (!![] +
                    [][
                      (![] + [])[+[]] +
                        (![] + [])[!+[] + !+[]] +
                        (![] + [])[+!+[]] +
                        (!![] + [])[+[]]
                    ])[+!+[] + [+[]]] +
                  (!![] + [])[+!+[]]
              ] + [])[+!+[] + [+!+[]]] +
              (!![] + [])[!+[] + !+[] + !+[]]
          ]
      ](!+[] + !+[] + !+[] + [!+[] + !+[] + !+[]]) +
      (+[![]] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+!+[]]] +
      ([][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ][
        ([][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ] + [])[!+[] + !+[] + !+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          ([][[]] + [])[+!+[]] +
          (![] + [])[!+[] + !+[] + !+[]] +
          (!![] + [])[+[]] +
          (!![] + [])[+!+[]] +
          ([][[]] + [])[+[]] +
          ([][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ] + [])[!+[] + !+[] + !+[]] +
          (!![] + [])[+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          (!![] + [])[+!+[]]
      ](
        (!![] + [])[+!+[]] +
          (!![] + [])[!+[] + !+[] + !+[]] +
          (!![] + [])[+[]] +
          ([][[]] + [])[+[]] +
          (!![] + [])[+!+[]] +
          ([][[]] + [])[+!+[]] +
          (![] + [+[]])[
            ([![]] + [][[]])[+!+[] + [+[]]] +
              (!![] + [])[+[]] +
              (![] + [])[+!+[]] +
              (![] + [])[!+[] + !+[]] +
              ([![]] + [][[]])[+!+[] + [+[]]] +
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
              (![] + [])[!+[] + !+[] + !+[]]
          ]()[+!+[] + [+[]]] +
          ![] +
          (![] + [+[]])[
            ([![]] + [][[]])[+!+[] + [+[]]] +
              (!![] + [])[+[]] +
              (![] + [])[+!+[]] +
              (![] + [])[!+[] + !+[]] +
              ([![]] + [][[]])[+!+[] + [+[]]] +
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
              (![] + [])[!+[] + !+[] + !+[]]
          ]()[+!+[] + [+[]]],
      )()[
        ([][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ] + [])[!+[] + !+[] + !+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          ([][[]] + [])[+!+[]] +
          (![] + [])[!+[] + !+[] + !+[]] +
          (!![] + [])[+[]] +
          (!![] + [])[+!+[]] +
          ([][[]] + [])[+[]] +
          ([][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ] + [])[!+[] + !+[] + !+[]] +
          (!![] + [])[+[]] +
          (!![] +
            [][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ])[+!+[] + [+[]]] +
          (!![] + [])[+!+[]]
      ] + [])[+!+[] + [!+[] + !+[]]] +
      (!![] + [])[+!+[]] +
      (!![] + [])[+!+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      (!![] + [])[+!+[]] +
      ([][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ] + [])[+!+[] + [!+[] + !+[] + !+[]]] +
      ([+[]] +
        ![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[!+[] + !+[] + [+[]]],
  )();
};
